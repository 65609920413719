import { postToSaleServer, postToApsServer, postToItemServer } from './common';
/**
 * 获得商品分类
 */
// async function getCats(){
//     return await postToSaleServer('/base/item/cat/list');
// }
// /**
//  * 获得商品风格
//  */
// async function getStyles(){
//     return await postToSaleServer('/base/item/style/list');
// }
/**
 * 获取公司信息，包含资质
 * @param {*} compId 
 */
export async function getCompanyInfo(compId){
    return await postToSaleServer('/base/compant/info/'+compId);
}

/**
 * 通用数据查询方法
 * @param {*} args 
 */
async function query(args){
    return await postToSaleServer('/query',args);
}
async function delShopByIds(ids){
    return await postToSaleServer('/shop/del_by_ids',{'ids':ids});
}
/**
 * 获得门店在第三方平台的商品列表
 * @param {*} type 
 * @param {*} shopid 
 */
async function getShopItems(type,shopid,ids,pageSize,pageNumber){
    return await postToSaleServer(`/shop/item/list/${type}/${shopid}`,{pageNumber:pageNumber,pageSize:pageSize,ids:ids});
}
/**
 * 获得门店在第三方平台的商品列表
 * @param {*} type 
 * @param {*} shopid
 */
async function getShopOrders(type,shopid,pageSize,pageNumber){
    return await postToSaleServer(`/shop/order/list/${type}/${shopid}`,{pageNumber:pageNumber,pageSize:pageSize});
}
/**
 * 添加商品至采购车
 * @param {*} itemId 商品id
 * @param {*} count 采购数量
 */
export async function addToPurCar(obj){
    return await postToSaleServer(`/pur/cart/save`,obj);
}
/**
 * 更新商品至采购车
 * @param {*} itemId 商品id
 * @param {*} count 采购数量
 */
export async function updatePurCar(row){
    return await postToSaleServer(`/pur/cart/save`,row);
}
/**
 * 显示购物车所有商品
 */
export async function listPurCar(obj){
    return await postToSaleServer(`/pur/cart/list`,obj);
}

/**
 * 返回购物车商品总数
 */
export async function countPurCar(){
    return await postToSaleServer(`/pur/cart/count`);
}

/**
 * 删除购物车商品
 * @param {*} ids 购物车记录id数组
 */
export async function delPurCar(ids){
    return await postToSaleServer(`/pur/cart/del`,{ids:ids});
}
/**
 * 结算购物车内选中的商品
 * @param {*} ids
 */
export async function payPurCar(ids,addressId,carriage){
    return await postToSaleServer(`/pur/cart/buy`,{ids:ids,addressId:addressId,carriage:carriage,});
}
/**
 * 显示采购订单列表(销售公司)
 * @param {} query 
 */
export async function listBuyPurOrder(query){
    return await postToSaleServer(`/pur/order/list/buy`,query);
}
/**
 * 显示采购订单列表（工厂）
 * @param {} query 
 */
export async function listSalePurOrder(query){
    return await postToSaleServer(`/pur/order/list/sale`,query);
}
/**
 * 查询指定采购订单
 * @param {*} query 
 */
export async function getOrderById(id){
    return await postToSaleServer(`/pur/order/get/${id}`);
}
/**
 * 查询指定采购订单的 APS 信息
 * @param {*} query 
 */
export async function getAPSByOrderId(id) {
    return await postToApsServer(`/nkapi/schedulesync/scheduled/${id}`);
}
// 物流-创建运单
 export async function createWaybill(query){
    return await postToItemServer(`/factory-market/create-waybill`,query);
}
// 物流-查询运单
export async function getWaybill(query){
    return await postToItemServer(`/factory-market/get-waybill`,query);
}
// 物流-查询运单物流轨迹
export async function trackWaybill(query){
    return await postToItemServer(`/factory-market/track-waybill`,query);
}
// 物流-批量查询订单的运单号
export async function queryOrderWaybill(query){
    return await postToItemServer(`/factory-market/query-order-waybill`,query);
}
/**
 * 删除采购订单
 * @param {*} ids 
 */
export async function delPurOrder(ids){
    return await postToSaleServer(`/pur/order/del`,{ids:ids});
}
/**
 * 采购订单修改价格
 * @param {*} ids 
 */
export async function entrieSetPrice(obj){
    return await postToSaleServer(`/pur/order/entrie/set_price`,obj);
}
/**
 * 确认采购订单
 * @param {*} ids 
 */
export async function qrPurOrder(id){
    return await postToSaleServer(`/pur/order/sale/confirm/${id}`);
}
/**
 * 收货人列表
 * @param {*} query 
 */
export async function listAddress(query){
    return await postToSaleServer(`/pur/address/list`,query);
}
/**
 * 查询指定收货人
 * @param {*} query 
 */
export async function getAddressById(id){
    return await postToSaleServer(`/pur/address/get/${id}`);
}
/**
 * 添加/更新收货人
 * @param {*} addrss 
 */
export async function saveAddress(addrss){
    return await postToSaleServer(`/pur/address/save`,addrss);
}
/**
 * 设置默认收货人
 * @param {*} id 
 */
export async function setDefaultAddress(id){
    return await postToSaleServer(`/pur/address/set_default/${id}`);
}
/**
 * 获得默认收货人
 */
export async function getDefaultAddress(){
    return await postToSaleServer(`/pur/address/get_default`);
}
/**
 * 删除收货人
 * @param {} ids 
 */
export async function delAddress(ids){
    return await postToSaleServer(`/pur/address/del`,{ids:ids});
}
/**
 * 上架总览列表
 * @param {} ids 
 */
export async function putawayAll(obj){
    return await postToSaleServer(`/shop/titem/list`,obj);
}
/*----------------------------收藏------------------------------*/
// 收藏商品
 export async function addFav(obj){
    return await postToItemServer(`/fav/add-fav`,obj);
}
// 取消收藏商品
export async function delFav(obj){
    return await postToItemServer(`/fav/del-fav`,obj);
}
// 查询商品是否已经收藏
export async function statFav(obj){
    return await postToItemServer(`/fav/stat-fav`,obj);
}
// 列表收藏的商品
export async function listFav(obj){
    return await postToItemServer(`/fav/list-fav`,obj);
}

export {query,delShopByIds,getShopItems,getShopOrders}